<template>
  <div>
    <v-row class="quantity-with-units">
      <v-col style="min-width: 130px; max-width: 130px" cols="6">
        <v-text-field
          v-model="item.quantity"
          class="mt-5 pb-0"
          type="number"
          min="0"
          outlined
          dense
          @input="$emit('input', item)"
        />
      </v-col>
      <v-col class="ml-n8" style="min-width: 125px; max-width: 125px" cols="6">
        <v-select
          v-model="item.quantityUnit"
          class="mt-5 ml-0 pb-0"
          :items="[
            {
              value: 'pkgs',
              text: item.quantity != 1 ? 'pkgs' : 'pkg',
            },
            {
              value: 'cases',
              text: item.quantity != 1 ? 'cases' : 'case',
            },
          ]"
          :value="'pkgs'"
          outlined
          dense
          hide-details
          @input="$emit('input', item)"
        ></v-select>
      </v-col>
    </v-row>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "package-or-case-field",
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({ quantity: 0, quantityUnit: "pkgs" }),
    },
  },
  data() {
    return {
      item: {},
    }
  },
  watch: {
    value: {
      handler(newVal) {
        this.item = newVal
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted() {
    this.item = this.value
    this.$emit("input", this.item)
  },
}
</script>

<style lang="scss">
.quantity-with-units {
  .col:first-child fieldset {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: -1px;
    border-right-color: transparent !important;
  }
  .col:last-child fieldset {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: -1px;
    border-left-color: transparent !important;
  }
}
</style>
