<template>
  <div>
    <v-menu
      ref="dateMenu"
      v-model="showDatePicker"
      :close-on-content-click="false"
      :return-value.sync="date"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :value="formattedDate"
          :label="formattedLabel"
          readonly
          prepend-inner-icon="mdi-calendar"
          v-bind="attrs"
          outlined
          hide-details
          :dense="dense"
          @click:prepend-inner="showDatePicker = !showDatePicker"
          v-on="on"
        />
      </template>
      <v-date-picker
        v-if="showDatePicker"
        v-model="date"
        show-adjacent-months
        full-width
        no-title
        @click:date="
          () => {
            if (!showTime) dateChanged()
          }
        "
      />
      <v-card class="pr-2 mb-n2">
        <v-row v-if="showTime" class="mx-0">
          <v-col cols="5" class="py-1">
            <v-checkbox v-model="customTime" label="Add Time" dense />
          </v-col>
          <v-col cols="7" class="py-1 px-0">
            <v-text-field
              v-if="showTime && customTime"
              v-model="time"
              step="1"
              hide-details
              :disabled="!customTime"
              type="time"
              class="mr-1 mb-n1 pt-0 mt-0"
            />
          </v-col>
        </v-row>
        <v-row v-if="showTime" class="mt-n2 mb-2">
          <v-col>
            <v-btn text color="lighttext" @click="showDatePicker = false">
              Cancel
            </v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn color="primary" @click="dateChanged">OK</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import moment from "moment"

export default {
  name: "custom-date-picker",
  props: {
    value: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
    dense: {
      type: Boolean,
      required: false,
      default: false,
    },
    showTime: {
      type: Boolean,
      required: false,
      default: false,
    },
    init: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      date: "",
      time: "",
      customTime: this.showTime ? true : false,
      showDatePicker: false,
    }
  },
  computed: {
    formattedLabel() {
      if (!this.label) {
        const label = this.showTime ? "Date and Time" : "Date"
        if (!this.formattedDate) {
          return "Select " + label
        }
        return label
      } else return this.label
    },
    formattedDate() {
      const showTime = this.time && this.customTime
      const formattedDate = this.date ? moment(this.date).format("MMM DD, YYYY") : ""
      if (!showTime) {
        return formattedDate
      } else
        return formattedDate + " " + moment(this.time, "HH:mm:ss").format("h:mm A")
    },
  },
  watch: {
    value(newVal) {
      ;[this.date, this.time] = newVal.split(" ")
    },
  },
  mounted() {
    if (this.init) {
      const [date, time] = this.value.split(" ")
      this.date = date || moment().format("YYYY-MM-DD")
      this.time = time || this.showTime ? moment().format("HH:mm:ss") : "00:00:00"
      this.dateChanged()
    }
  },
  methods: {
    dateChanged() {
      this.$refs.dateMenu.save(this.date)
      this.$emit("input", this.date + " " + this.time)
      this.showDatePicker = false
    },
  },
}
</script>
