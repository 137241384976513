var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{staticClass:"quantity-with-units"},[_c('v-col',{staticStyle:{"min-width":"130px","max-width":"130px"},attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"mt-5 pb-0",attrs:{"type":"number","min":"0","outlined":"","dense":""},on:{"input":function($event){return _vm.$emit('input', _vm.item)}},model:{value:(_vm.item.quantity),callback:function ($$v) {_vm.$set(_vm.item, "quantity", $$v)},expression:"item.quantity"}})],1),_c('v-col',{staticClass:"ml-n8",staticStyle:{"min-width":"125px","max-width":"125px"},attrs:{"cols":"6"}},[_c('v-select',{staticClass:"mt-5 ml-0 pb-0",attrs:{"items":[
          {
            value: 'pkgs',
            text: _vm.item.quantity != 1 ? 'pkgs' : 'pkg',
          },
          {
            value: 'cases',
            text: _vm.item.quantity != 1 ? 'cases' : 'case',
          },
        ],"value":'pkgs',"outlined":"","dense":"","hide-details":""},on:{"input":function($event){return _vm.$emit('input', _vm.item)}},model:{value:(_vm.item.quantityUnit),callback:function ($$v) {_vm.$set(_vm.item, "quantityUnit", $$v)},expression:"item.quantityUnit"}})],1)],1),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }